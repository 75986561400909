import React from "react";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { media } from "../../helpers";

import Wrapper from "../../elements/wrapper";

const MoreProductsContainer = styled.div`
  padding: 100px 0;
  .title-container {
    text-align: center;
    padding-bottom: 100px;

    &.no-cap {
      padding-bottom: 30px;
    }
    h3 {
      color: #222;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 32px;
      margin-bottom: 0px;
      ${media.medium`font-size: 42px;`}
    }

    .offering-description {
      text-align: left;
      font-weight: 300;

      p {
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .products-wrapper {
    .product-grid {
      ${media.small`display: flex;flex-wrap: wrap;`}
      margin: 0 -15px;
    }
    .item-container {
      flex: 0 0 50%;
      margin-bottom: 30px;
      padding: 0 15px;
      .inner-wrap {
        .image-container {
          position: relative;

          .gatsby-image-wrapper {
            max-height: 350px;
            overflow: hidden;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 50%);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .image-title {
            h2 {
              color: #fff;
              margin-bottom: 0px;
              text-transform: uppercase;
              font-weight: 900;
              font-size: 42px;
              border: 4px solid #fff;
              padding: 10px 0;
              min-width: 340px;

              text-align: center;
            }
          }
        }
        .content-container {
          font-weight: 300;
          font-size: 16px;
          line-height: 1.4;
          padding: 10px 0 0;
          text-align: center;
        }
      }
    }
  }
`;

const MoreProducts = ({ settings }) => {
  const { sectionTitle, moreProducts } = settings || {};
  return (
    <MoreProductsContainer>
      <Wrapper className="title-container">
        <h3>{sectionTitle}</h3>
      </Wrapper>
      <Wrapper className="products-wrapper">
        <div className="product-grid">
          {moreProducts.map((product, productIndex) => {
            const { productImage, productLink } = product || {};

            let image = getImage(productImage?.localFile);
            return (
              <div className="item-container" key={productIndex}>
                <div className="inner-wrap">
                  <Link to={productLink?.url}>
                    <div className="image-container">
                      <GatsbyImage
                        alt={productLink.title + " image"}
                        image={image}
                      />
                      <div className="overlay">
                        <div className="image-title">
                          <h2>{productLink?.title}</h2>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </MoreProductsContainer>
  );
};

export default MoreProducts;
