import React from "react";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

const SplitBrandImageGridContainer = styled.div`
  .brand-image-wrapper {
    ${media.small`display: flex;flex-wrap: wrap; margin: 0 -30px;margin-bottom: -30px;`}

    .image-container {
      flex: 0 0 33.33%;
      margin-bottom: 30px;
      padding: 0 15px;

      .gatsby-image-wrapper {
        overflow: hidden;
        ${media.small`max-height: 280px; padding: 0 30px;`}
        ${media.large`max-height: 350px;`}
      ${media.laptop`max-height: 400px;`}
      }
    }
  }
`;

const SplitBrandImageGrid = ({ rows }) => {
  return (
    <SplitBrandImageGridContainer>
      <Wrapper className="brand-image-container">
        <div className="brand-image-wrapper">
          {rows.map((row, rowIndex) => {
            let image = getImage(row?.brandImage?.localFile || null);
            return (
              <div className="image-container" key={rowIndex}>
                <GatsbyImage image={image} />
              </div>
            );
          })}
        </div>
      </Wrapper>
    </SplitBrandImageGridContainer>
  );
};

export default SplitBrandImageGrid;
