import React, { useContext } from "react";
import styled from "styled-components";

import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import SingleProduct from "../components/page-components/single-product";

import { WebsiteContext } from "../context/WebsiteContext";

const ProductTemplateContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const ProductTemplate = ({ data }) => {
  let page = data?.product?.edges[0]?.node || {};
  const { distanceFromTop } = useContext(WebsiteContext);

  return (
    <Layout>
      <SEO title={page.title} />
      <ProductTemplateContainer distanceFromTop={distanceFromTop}>
        <SingleProduct data={data} />
      </ProductTemplateContainer>
    </Layout>
  );
};

export default ProductTemplate;

export const query = graphql`
  query($slug: String!) {
    product: allWpProduct(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          content
          slug
          single_product_acf {
            productBannerSettings {
              bannerHeadline
              bannerImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
            productImageGrid {
              brandImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
            moreProductsSettings {
              sectionTitle
              moreProducts {
                productLink {
                  title
                  url
                }
                productImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
